import type { Page } from '~/types/api/page'
import type { Tag } from '~/types/api/tag'

export function useHeadTitle ({ pageContent }: { pageContent: Ref<Page | Tag | null> }) {
  if (!pageContent?.value) {
    return 'Blog Yescapa'
  }

  if ('meta' in pageContent.value) {
    return `${pageContent.value.meta.seo_title || pageContent.value.title}`
  }

  return `${pageContent.value.name}`
}
