export function useHeadLink ({ addAlternate, addSearchParams = [], preloads = [] }: { addAlternate: boolean; addSearchParams: string[], preloads?: PreloadDefinition[] }) {
  const { public: { i18n: i18nRuntimeConfig } } = useRuntimeConfig()
  const { protocol, origin, pathname, searchParams } = useRequestURL()

  const i18nRuntimeConfigLocaleCodes = (Object.keys(i18nRuntimeConfig.locales) as Array<keyof typeof i18nRuntimeConfig.locales>)

  const linksAlternate = []
  // Special case for blog as it has translated urls and specific content per locale we can't provide alternate links
  if (addAlternate) {
    linksAlternate.push(...i18nRuntimeConfigLocaleCodes.map(localeCode => ({
      rel: 'alternate',
      href: `${protocol}//${i18nRuntimeConfig.locales[localeCode].domain}`,
      hreflang: i18nRuntimeConfig.locales[localeCode].iso,
    })))
  }

  const canonicalHref = new URL(`${origin}${pathname}`)
  addSearchParams.forEach((p) => {
    if (searchParams.has(p)) {
      canonicalHref.searchParams.set(p, searchParams.get(p) as string)
    }
  })

  const linkCanonical = { rel: 'canonical', href: canonicalHref.href }

  return [
    ...linksAlternate,
    linkCanonical,
    ...preloads.map(definition => ({ rel: 'preload', as: 'image', ...definition })),
  ]
}
