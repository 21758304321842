import type { Page } from '~/types/api/page'
import type { Tag } from '~/types/api/tag'

export function useHeadMeta ({ pageContent }: { pageContent: Ref<Page | Tag | null > }) {
  const { locale } = useI18n()
  const { public: { i18n: { locales } } } = useRuntimeConfig()

  let description
  if (!pageContent?.value) {
    description = ''
  } else if ('meta' in pageContent.value) {
    description = pageContent.value.meta.search_description
  } else {
    description = pageContent.value.sub_title
  }

  const availableLocalesCodes = (Object.keys(locales) as Array<keyof typeof locales>)
  const metasOgLocaleAlternate = availableLocalesCodes.filter(localeCode => localeCode !== locale.value).map(localeCode => ({
    property: 'og:locale:alternate', content: locales[localeCode].iso,
  }))
  return [
    { property: 'name', content: 'Yescapa Blog' },
    { property: 'author', content: 'Yescapa' },
    { name: 'description', content: description },
    { property: 'og:title', content: useHeadTitle({ pageContent }) },
    { property: 'og:description', content: description },
    { property: 'og:site_name', content: 'Yescapa Blog' },
    { property: 'og:image', content: useSeoImageURL(pageContent?.value?.featured_image_rendered?.url_relative || '', { width: 1200, height: 630 }) },
    { property: 'og:locale', content: locale.value },
    ...metasOgLocaleAlternate,
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: '@Yescapa' },
    { name: 'twitter:creator', content: 'Yescapa' },
    { name: 'robots', content: 'max-image-preview:large' },
  ]
}
